import { configure } from 'mobx'
import { enableStaticRendering } from 'mobx-react'

const isServer = typeof window === 'undefined'
configure({ enforceActions: 'observed' })
enableStaticRendering(isServer)

class FactoryModel {
  ResponsiveModel = null
  SettingsModel = null
  ConfigModel = null
  DictModel = null
  UpdateModel = null
  AuthModel = null
  AdditionalServiceListModel = null
  MenuModel = null
  QuestionaryListModel = null
  QuestionaryModel = null
  PickupModel = null
  CreditRequestListModel = null
  CreditRequestModel = null
  UserModel = null
  UserListModel = null
  BankModel = null
  BankMixModel = null
  PointListModel = null
  PointModel = null
  PartnerListModel = null
  PartnerModel = null
  AcceptanceCertificateModel = null
  QuestionaryDashboardModel = null
  TutorialModel = null
  AgentCommissionGroupListModel = null
  AgentRewardListModel = null
  AgentRewardModel = null
  AccreditationModel = null
  PartnerPropositionModel = null
  PartnerApplicationListModel = null
  AnalyticsModel = null
  PostModel = null
  ContentModel = null
  PartnerFormModel = null
  CallBackModel = null
  CreditOfferModel = null
  IdeaModel = null

  init({
    ConfigModel,
    ResponsiveModel,
    SettingsModel,
    DictModel,
    UpdateModel,
    AuthModel,
    AdditionalServiceListModel,
    MenuModel,
    QuestionaryListModel,
    QuestionaryModel,
    PickupModel,
    CreditRequestListModel,
    CreditRequestModel,
    UserModel,
    UserListModel,
    BankModel,
    BankMixModel,
    PointListModel,
    PointModel,
    PartnerListModel,
    PartnerModel,
    AcceptanceCertificateModel,
    QuestionaryDashboardModel,
    TutorialModel,
    AgentCommissionGroupListModel,
    AgentRewardListModel,
    AgentRewardModel,
    AccreditationModel,
    PartnerPropositionModel,
    PartnerApplicationListModel,
    AnalyticsModel,
    PostModel,
    ContentModel,
    PartnerFormModel,
    CallBackModel,
    CreditOfferModel,
    IdeaModel
  }) {
    this.ResponsiveModel = ResponsiveModel
    this.ConfigModel = ConfigModel
    this.DictModel = DictModel
    this.SettingsModel = SettingsModel
    this.UpdateModel = UpdateModel
    this.AuthModel = AuthModel
    this.AdditionalServiceListModel = AdditionalServiceListModel
    this.QuestionaryListModel = QuestionaryListModel
    this.QuestionaryModel = QuestionaryModel
    this.PickupModel = PickupModel
    this.CreditRequestListModel = CreditRequestListModel
    this.CreditRequestModel = CreditRequestModel
    this.MenuModel = MenuModel
    this.UserModel = UserModel
    this.UserListModel = UserListModel
    this.BankModel = BankModel
    this.BankMixModel = BankMixModel
    this.PointListModel = PointListModel
    this.PointModel = PointModel
    this.PartnerListModel = PartnerListModel
    this.PartnerModel = PartnerModel
    this.AcceptanceCertificateModel = AcceptanceCertificateModel
    this.QuestionaryDashboardModel = QuestionaryDashboardModel
    this.TutorialModel = TutorialModel
    this.AgentCommissionGroupListModel = AgentCommissionGroupListModel
    this.AgentRewardListModel = AgentRewardListModel
    this.AgentRewardModel = AgentRewardModel
    this.AccreditationModel = AccreditationModel
    this.PartnerPropositionModel = PartnerPropositionModel
    this.PartnerApplicationListModel = PartnerApplicationListModel
    this.AnalyticsModel = AnalyticsModel
    this.PostModel = PostModel
    this.ContentModel = ContentModel
    this.PartnerFormModel = PartnerFormModel
    this.CallBackModel = CallBackModel
    this.CreditOfferModel = CreditOfferModel
    this.IdeaModel = IdeaModel
  }
}

export { FactoryModel }
export default new FactoryModel()
