import React from 'react'
import _ from 'lodash'

const prepareErrorMessage = err => {
  let message = 'Неизвестная ошибка'

  if (!err || !err.response) return message

  const response = err.response
  const { data, status } = response
  if (!data) return message
  if (status === 500) return 'Внутренняя ошибка сервера'

  const generateError = source => (_.isObject(source) ? JSON.stringify(source) : source)

  if (_.isString(data.message)) return generateError(data.message)
  if (data.error) return generateError(data.error)

  if (_.isObject(data?.message)) {
    const elements = []
    _.each(data?.message, (value, key) => {
      if (_.isArray(value)) {
        _.each(value, v => {
          elements.push(<li key={key}>{key}: {_.isString(v) ? v : JSON.stringify(v)}</li>)
        })
      } else {
        elements.push(<li key={key}>{value}</li>)
      }
    })

    return (
      <ul style={{ marginLeft: '-24px' }}>
        {elements}
      </ul>
    )
  }

  if (_.isString(data.errors)) return generateError(data.errors)
  if (data?.errors) {
    const elements = []
    _.each(data?.errors, (d, i) => {
      if (!_.isArray(d)) {
        let keyObject = ''
        let valueObject = ''
        _.each(d, (value, key) => {
          keyObject = key
          valueObject = _.isArray(value) ? _.get(value, '0') : JSON.stringify(value)
          return false
        })
        elements.push(<li key={i}>{keyObject}: {valueObject}</li>)
      } else {
        _.each(d, el => {
          elements.push(<li key={i}>{i}: {el}</li>)
        })
      }
    })
    message = (
      <ul style={{ marginLeft: '-24px' }}>
        {elements}
      </ul>
    )
  }
  return message
}

export { prepareErrorMessage }
