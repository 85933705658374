class FactoryProvider {
  StorageProvider = null
  ConfigProvider = null

  AuthProvider = null
  SettingsProvider = null
  DictProvider = null
  UserProvider = null
  RegionProvider = null
  CallCenterProvider = null
  PartnerProvider = null
  PointProvider = null
  BankProvider = null
  DadataProvider = null
  RetailProvider = null
  ServiceProvider = null
  ActProvider = null
  AgentCommissionGroupsProvider = null
  AgentRewardProvider = null
  AccreditationProvider = null
  PoscansProvider = null
  PickupProvider = null
  TutorialProvider = null
  RefundProvider = null
  PostProvider = null
  ContentProvider = null

  ActionCableProvider = null
  PoscansWsProvider = null
  RequestCallProvider = null
  PartnerFormProvider = null
  CreditOfferProvider = null
  IdeaProvider = null

  init({
    StorageProvider,
    ConfigProvider,

    AuthProvider,
    SettingsProvider,
    DictProvider,
    QuestionaryProvider,
    RefundProvider,
    CreditRequestProvider,
    UserProvider,
    RegionProvider,
    CallCenterProvider,
    PartnerProvider,
    PointProvider,
    BankProvider,
    DadataProvider,
    RetailProvider,
    ServiceProvider,
    ActProvider,
    AgentCommissionGroupsProvider,
    AgentRewardProvider,
    AccreditationProvider,
    PoscansProvider,
    PickupProvider,
    TutorialProvider,
    PostProvider,
    ContentProvider,

    ActionCableProvider,
    PoscansWsProvider,
    RequestCallProvider,
    PartnerFormProvider,
    CreditOfferProvider,
    IdeaProvider
  }) {
    this.StorageProvider = StorageProvider
    this.ConfigProvider = ConfigProvider

    this.AuthProvider = AuthProvider
    this.SettingsProvider = SettingsProvider
    this.DictProvider = DictProvider
    this.QuestionaryProvider = QuestionaryProvider
    this.RefundProvider = RefundProvider
    this.CreditRequestProvider = CreditRequestProvider
    this.UserProvider = UserProvider
    this.RegionProvider = RegionProvider
    this.CallCenterProvider = CallCenterProvider
    this.PartnerProvider = PartnerProvider
    this.PointProvider = PointProvider
    this.BankProvider = BankProvider
    this.DadataProvider = DadataProvider
    this.RetailProvider = RetailProvider
    this.ServiceProvider = ServiceProvider
    this.ActProvider = ActProvider
    this.AgentCommissionGroupsProvider = AgentCommissionGroupsProvider
    this.AgentRewardProvider = AgentRewardProvider
    this.AccreditationProvider = AccreditationProvider
    this.PoscansProvider = PoscansProvider
    this.PickupProvider = PickupProvider
    this.TutorialProvider = TutorialProvider
    this.PostProvider = PostProvider
    this.ContentProvider = ContentProvider

    this.ActionCableProvider = ActionCableProvider
    this.PoscansWsProvider = PoscansWsProvider
    this.RequestCallProvider = RequestCallProvider
    this.PartnerFormProvider = PartnerFormProvider
    this.CreditOfferProvider = CreditOfferProvider
    this.IdeaProvider = IdeaProvider
  }
}

export { FactoryProvider }
export default new FactoryProvider()
